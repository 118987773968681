<template>
    <GuestLayout :title="$t('auth.login')">
        <Container size="xs" class="w-full">
            <h2 class="mt-6 text-center text-4xl font-light text-gray-900 lg:text-3xl">
                {{ $t('auth.login') }}
            </h2>
            <h3 class="mt-3 text-center text-lg lg:text-xl">
                {{ $t('auth.new_user') }}
                <Link :href="route('register')" class="font-bold text-gray-900">{{ $t('auth.register') }}</Link>
            </h3>

            <Card class="mt-6 p-4 sm:p-6">
                <form @submit.prevent="submit" class="space-y-6">
                    <PhoneInput
                        :label="$t('auth.phone_number')"
                        id="phone_number"
                        type="text"
                        v-model="form.phone_number"
                        :error="form.errors.phone_number"
                        required
                        autofocus
                    />

                    <PButton type="submit" :loading="form.processing" class="group relative w-full">
                        {{ $t('actions.next') }}
                        <span class="absolute inset-y-0 right-0 flex items-center pr-3">
                            <ArrowRightIcon
                                class="h-5 w-5 text-primary-300 group-hover:text-primary-200"
                                aria-hidden="true"
                            />
                        </span>
                    </PButton>
                </form>
            </Card>
        </Container>
    </GuestLayout>
</template>

<script setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import { Link, useForm } from '@inertiajs/vue3';
import { Container, Card, PButton } from '@/Components';
import { PhoneInput } from '@/Components/Form';
import { ArrowRightIcon } from '@heroicons/vue/24/solid';

const form = useForm({
    phone_number: '',
});

const submit = () => {
    form.get(route('login.verify'));
};
</script>
